import React from 'react';
import { Link } from 'react-router-dom';
import * as ROUTES from '../../constants/routes';

import { withFirebase } from '../Firebase';

const SignOutButton = ({ firebase }) => (
  <Link to={ROUTES.LANDING} onClick={firebase.doSignOut}>
    로그아웃
  </Link>
);

export default withFirebase(SignOutButton);
