import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { AuthUserContext } from '../Session';
import SignOutButton from '../SignOut';
import * as ROUTES from '../../constants/routes';
import * as ROLES from '../../constants/roles';
import clsx from 'clsx';
import * as AiIcons from 'react-icons/ai';
import { Menu as MenuIcon, List as ListIcon, Label as LabelIcon } from '@material-ui/icons';
import {
  AppBar,
  Divider,
  Toolbar,
  IconButton,
  Box,
  Button,
  SwipeableDrawer,
  List,
  ListItem,
  Typography,
  ListItemText,
  ListItemIcon,
  makeStyles,
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  list: {
    width: 250,
  },
  fullList: {
    width: 'auto',
  },
}));

export default function Navigation() {
  const classes = useStyles();
  const [state, setState] = useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setState({ ...state, [anchor]: open });
  };

  const list = (anchor) => (
    <div
      className={clsx(classes.list, {
        [classes.fullList]: anchor === 'top' || anchor === 'bottom',
      })}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <Link to='#' className='menu-bars'>
        <AiIcons.AiOutlineClose />
      </Link>
      <AuthUserContext.Consumer>
        {authUser =>
          authUser ? (
            <NavigationAuth authUser={authUser} />
          ) : (
              <NavigationNonAuth />
            )
        }
      </AuthUserContext.Consumer>
    </div >
  );

  return (
    <AppBar position="static">
      <Toolbar>
        <IconButton edge="start" color="inherit" aria-label="menu" onClick={toggleDrawer('left', true)} >
          <MenuIcon />
        </IconButton>
        <Typography variant="h6" className={classes.title}>
          피아노
        </Typography>
        {/* <Button color="inherit">Login</Button> */}
      </Toolbar>

      <SwipeableDrawer
        anchor={'left'}
        open={state['left']}
        onClose={toggleDrawer('left', false)}
        onOpen={toggleDrawer('left', true)}
      >
        {list('left')}
      </SwipeableDrawer>
    </AppBar>
  );
}

const NavigationAuth = ({ authUser }) => (
  <List>
    <ListItem button key='Landing' component={Link} to={ROUTES.LANDING} >
      <ListItemText primary='Landing' />
    </ListItem>
    <ListItem button key='홈' component={Link} to={ROUTES.HOME}>
      <ListItemText primary='홈' />
    </ListItem>
    <Link to={ROUTES.ACCOUNT}>
      <ListItem button key='계정'>
        <ListItemText primary='계정' />
      </ListItem>
    </Link>
    {!!authUser.roles[ROLES.ADMIN] && (
      <Link to={ROUTES.ADMIN}>
        <ListItem button key='어드민'>
          <ListItemText primary='어드민' />
        </ListItem>
      </Link>
    )}
    <ListItem button key='로그아웃'>
      <SignOutButton />
    </ListItem>
  </List>
);

const NavigationNonAuth = () => (
  <List>
    <ListItem button key='Landing' component={Link} to={ROUTES.LANDING}>
      <ListItemText primary='Landing' />
    </ListItem>
    <ListItem button key='로그인' component={Link} to={ROUTES.SIGN_IN}>
      <ListItemText primary='로그인' />
    </ListItem>
  </List>
);